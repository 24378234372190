<script>
  import { _, locale } from "svelte-i18n";
  import NavigationTop from "../../components/navigation/top-component.svelte";
  import Reservations from "../../reservations-service";
  import PropertyFeatures from "../../property-features-service";
  import LocationPointer from "../../components/location/pointer-element/component.svelte";
  import CardFeature from "../card-feature/component.svelte";
  import Reloading from "../../components/navigation/reloading-component.svelte";
  import { querystring, push } from "svelte-spa-router";

  export let params = {};
  export let page = "home";
  export let onFinishedLoading;

  let requestsPending = 0;
  let state = Object.assign(
    { features: [], reservation: undefined },
    params,
    !params.propertyId ? { propertyId: 89 } : {}
  );
  let faeturesList = [];
  let amountFeatures = 0;
  let propertyData = {};
  let managementColors = {};
  let backgroundColorView;
  let reload = true;
  let language;
  let languagesEnabled = [];
  let i = 0;
  let iframeFromLanding = false;
  let auth;
  let propEmpty = "";
  let lengthNamesPropery = 0;
  let screenPxs;
  let rowCardRequest = false;
  let queryHidden = null;
  let requestsList = [];
  let dataHueader = null;
  let browserIdentify = JSON.parse(localStorage.getItem("browser-identify"));
  localStorage.getItem("managementColors")
    ? (managementColors = JSON.parse(localStorage.getItem("managementColors")))
    : (managementColors = {
        color: "",
        title: "",
        general: "",
      });

  let reservationId = JSON.parse(localStorage.getItem("auth"))
    ? JSON.parse(localStorage.getItem("auth")).reservationId
    : null;

  const validationColors = (guestappsettins) => {
    managementColors = {
      filter: guestappsettins.filter,
      color: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .color,
      title: Reservations.getColorComponentsAndIcons(guestappsettins.color)
        .title,
      general: Reservations.getColorsContrast(guestappsettins),
    };

    localStorage.setItem("managementColors", JSON.stringify(managementColors));
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };

  const validationCallIframe = () => {
    $querystring.split("hidden") && $querystring.split("hidden=")[1]
      ? (queryHidden = $querystring.split("hidden=")[1].split("&")[0])
      : (queryHidden = "");

    $querystring ? (iframeFromLanding = true) : (iframeFromLanding = false);

    if ($querystring.split("lang=")[1]) {
      locale.set($querystring.split("lang=")[1]);
      localStorage.setItem(
        "language",
        JSON.stringify($querystring.split("lang=")[1])
      );
    }
  };

  validationCallIframe();

  const init = (lang) => {
    i = 0;
    return Promise.all([
      PropertyFeatures.list(
        { propertyId: state.propertyId },
        lang,
        queryHidden
      ),
      PropertyFeatures.guestAppSettings({ propertyId: state.propertyId }),
      PropertyFeatures.proppertyInformation({
        propertyId: state.propertyId,
        reservationId: state.reservationId,
        browserIdentify,
      }),
    ]).then(([features, guestappsettins, propertyInformation]) => {
      faeturesList = features;

      faeturesList.map((fet) => {
        if (fet.enabled) amountFeatures = amountFeatures + 1;
      });

      validationAmountFeatures();

      propertyData = propertyInformation;

      validationLanguage(
        guestappsettins.languages,
        propertyInformation.language ? propertyInformation.language.code : "EN"
      );

      validationDataHeader({ codename: "home" });

      validationColors(guestappsettins);

      setState({ features });

      validationRouteInitial(amountFeatures);

      loginandTimeOutLogin();

      validationLengthNamesProperty();

      setFontFamily(guestappsettins.fontFamily);
      screenPxs = document.getElementById("headerid")?.offsetWidth;

      return true;
    });
  };

  const validationLanguage = (enabledLanguges, languageDefault) => {
    localStorage.setItem("languagesConfig", JSON.stringify(enabledLanguges));
    languagesEnabled = enabledLanguges;
    language = languageDefault;
  };

  const validationAmountFeatures = () => {
    let cardRequest = faeturesList.find(
      (fet) => fet.codename == "requests.create"
    );
    let numOrder = 0;
    faeturesList.map((feat) => {
      feat.numOrder = numOrder;
      numOrder++;
    });
    if (
      cardRequest &&
      cardRequest.settings.row == 2 &&
      cardRequest.numOrder % 2 == 0
    ) {
      rowCardRequest = true;
    }
    faeturesList.sort(function (a, b) {
      return a.numOrder - b.numOrder;
    });
  };

  let validationDataHeader = (feature) => {
    if (!$querystring.split("var")[1]) {
      switch (feature.codename) {
        case "home":
          var tile = {
            featureId: 1,
            nameFeature: "Home",
            codeName: "home",
            imgFeature: propertyData.logo,
            link: `/properties/${state.propertyId}`,
          };
          break;
        case "requests.create":
          var tile = {
            featureId: 1,
            codeName: "requests.create",
            nameFeature: faeturesList.find(
              (fet) => fet.codename == "requests.create"
            ).caption,
            imgFeature: faeturesList.find(
              (fet) => fet.codename == "requests.create"
            ).imgUrl,
            link: `/properties/${state.propertyId}/create-requests`,
          };
          break;
        case "property.details":
          var tile = {
            featureId: feature._id,
            nameFeature: feature.caption,
            codeName: feature.codename,
            imgFeature: feature.imgUrl,
            link: `/properties/${state.propertyId}`,
          };
          break;
        case "myrequests":
          var tile = {
            featureId: 1,
            nameFeature: "Home",
            codeName: "myrequests",
            imgFeature: propertyData.logo,
            link: `/properties/${state.propertyId}`,
          };
          break;
        case "restaurants.list":
          var tile = {
            featureId: feature._id,
            nameFeature: feature.caption,
            codeName: feature.codename,
            imgFeature: feature.imgUrl,
            link: `/properties/${state.propertyId}/restaurants`,
          };
          break;
        case "room-service.list":
          var tile = {
            featureId: feature._id,
            nameFeature: feature.caption,
            codeName: feature.codename,
            imgFeature: feature.imgUrl,
            link: `/properties/${state.propertyId}/room-services`,
          };
          break;
        case "pickup":
          var tile = {
            featureId: feature._id,
            nameFeature: "Pickup",
            codeName: "pickup",
            imgFeature: feature.imgUrl,
            link: `/properties/${state.propertyId}`,
          };
          break;

        default:
          var tile = {
            featureId: feature._id,
            nameFeature: feature.caption,
            codeName: feature.codename,
            imgFeature: feature.imgUrl,
            link: `/properties/${state.propertyId}/feature/${feature._id}/list-items`,
          };
      }
    } else {
      var tile = {
        featureId: 1,
        nameFeature: "Home",
        codeName: "home",
        imgFeature: propertyData.logo,
        link: `/properties/${state.propertyId}`,
      };
    }
    localStorage.setItem("dataHeader", JSON.stringify(tile));
    localStorage.removeItem("entryPoint");
    dataHueader = tile;
    return Promise.resolve();
  };

  const validationRouteInitial = (count) => {
    if (count == 1) {
      let query = "?var=iframe";

      if (iframeFromLanding) {
        push(`/properties/${state.propertyId}/information` + query);
      } else {
        push(`/properties/${state.propertyId}/information`);
      }

      localStorage.setItem("showBtnBack", JSON.stringify(false));
    } else {
      reload = false;
      if (onFinishedLoading) {
        validationDataHeader({ codename: page }).then(() =>
          onFinishedLoading()
        );
      }
    }
  };

  let requestsPendingOutput = (data) => {
    if (data) {
      requestsList = data
        .filter((req) => {
          if (
            req.status == "Validate" ||
            req.status == "Accepted" ||
            req.status == "Processed" ||
            req.status == "Waiting" ||
            req.status == "On van"
          )
            return req;
        })
        .sort((a, b) => b.executionDate - a.executionDate);
      requestsPending = requestsList.length;
    }

    auth = localStorage.getItem("auth")
      ? Object.assign({}, JSON.parse(localStorage.getItem("auth")), {
          guestName: JSON.parse(localStorage.getItem("auth")).guestName
            ? JSON.parse(localStorage.getItem("auth")).guestName.toString()
                .length > 20
              ? JSON.parse(localStorage.getItem("auth"))
                  .guestName.toString()
                  .slice(0, 20) + "..."
              : JSON.parse(localStorage.getItem("auth")).guestName.toString()
            : "",
        })
      : null;
  };

  const goModule = (feature) => {
    validationDataHeader(feature);

    JSON.parse(localStorage.getItem("showBtnBack")) == false
      ? localStorage.removeItem("showBtnBack")
      : null;

    if (!$querystring) {
      if (feature.isDefault) {
        if (
          feature.codename == "room-service.list" ||
          (feature.codename == "requests.create" &&
            feature.settings.loginRequired)
        ) {
          confirAuth(feature.href[0].link);
        } else {
          push(feature.href[0].link);
        }
      } else {
        if (
          feature.codename == "other" ||
          feature.codename == "restaurant" ||
          feature.codename == "service"
        ) {
          if (feature.codename == "other" || feature.codename == "restaurant") {
            localStorage.setItem("amountRestaurants", JSON.stringify(1));
          } else {
            localStorage.setItem("amountServices", JSON.stringify(1));
          }

          push(`${feature.href[0].link}`);
        } else if (feature.codename == "promotion") {
          push(
            `/properties/${state.propertyId}/section/${feature.codename}/${feature.outletId}`
          );
        } else if (feature.href.length == 1) {
          goFile(
            feature.href[0].link,
            feature.type,
            feature.href[0].canIframe,
            feature
          );
        } else {
          push(
            `/properties/${state.propertyId}/feature/${feature._id}/list-items`
          );
        }
      }
    }
  };

  let validationTypeFile = (href, typeFile, validUrl) => {
    if (
      (typeFile == "link" && validUrl) ||
      href.split("services.").length == 2
    ) {
      return "IframeItem";
    } else if (
      /\.(png)$/i.test(href) ||
      /\.(jpg)$/i.test(href) ||
      /\.(jpeg)$/i.test(href) ||
      /\.(gif)$/i.test(href) ||
      /\.(bmp)$/i.test(href) ||
      /\.(tif)$/i.test(href) ||
      /\.(tiff)$/i.test(href)
    ) {
      return "ImgItem";
    } else if (typeFile == "link" && !validUrl) {
      return "OtherTab";
    } else {
      return "fileDownload";
    }
  };

  let goFile = (href, typeFile, validUrl, feature) => {
    let type = validationTypeFile(href, typeFile, validUrl);

    if (type == "IframeItem") {
      JSON.parse(localStorage.getItem("ImgItem"))
        ? localStorage.removeItem("ImgItem")
        : null;

      localStorage.setItem("IframeItem", JSON.stringify(href));
      push(
        `/properties/${state.propertyId}/feature/${feature._id}/detail-item/${feature.codename}`
      );
    } else if (type == "ImgItem") {
      JSON.parse(localStorage.getItem("IframeItem"))
        ? localStorage.removeItem("IframeItem")
        : null;

      localStorage.setItem("ImgItem", JSON.stringify(href));
      push(
        `/properties/${state.propertyId}/feature/${feature._id}/detail-item/${feature.codename}`
      );
    } else if (type == "OtherTab") {
      window.open(href, "_blank").focus();
    } else {
      let pdfDefault = `https://docs.google.com/viewerng/viewer?url=${href}`;
      //handleFileDownloading(true);
      window.location.href = pdfDefault;
    }
  };

  let confirAuth = (route) => {
    let credentials = JSON.parse(localStorage.getItem("auth"));
    let reservation = JSON.parse(localStorage.getItem("reservation"));
    var nowDate = new Date().getTime();

    if (credentials) {
      reload = true;
      let data = {
        code: credentials.code,
      };

      reload = false;
      if (onFinishedLoading) {
        validationDataHeader({ codename: page }).then(() =>
          onFinishedLoading()
        );
      }

      push(route);
    } else {
      push(`/properties/${state.propertyId}/user-validation?router=${route}`);
    }
  };

  let loginandTimeOutLogin = () => {
    var reservation = JSON.parse(localStorage.getItem("reservation"));
    let credentials = JSON.parse(localStorage.getItem("auth"));
    var nowDate = new Date().getTime();

    if (
      (reservation && nowDate - reservation >= 86400000) ||
      (credentials && credentials.propertyId != state.propertyId)
    ) {
      localStorage.removeItem("reservation");
      localStorage.removeItem("auth");
    }
  };

  let validationLengthNamesProperty = () => {
    if (screenPxs < 600) {
      lengthNamesPropery = 20;
    } else if (screenPxs > 600 && screenPxs < 992) {
      lengthNamesPropery = 60;
    } else {
      lengthNamesPropery = 100;
    }
  };

  let setFontFamily = (fontFamily) => {
    PropertyFeatures.setFontFamily(fontFamily);
  };

  let handleLanguage = (e) => {
    init(e);
  };

  const setState = (input) => (state = Object.assign(state, input));
  init(
    localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : window.navigator.language.split("-")[0]
  );
  const messageNotRead = (messages) => {
    return find(messages, (m) => !m.read) ? true : false;
  };
  let mySroll = false;
  const scollMove = () => {
    const button = document.querySelector("#cards-lits");
    mySroll = true;
    button.scrollLeft += 600;
  };
  const scollMoveLeft = () => {
    const button = document.querySelector("#cards-lits");

    button.scrollLeft -= 600;
  };

  let goMyRequests = () => {
    push(`/properties/${state.propertyId}/my-experiences`);
  };

  let goMyItem = (data) => {
    if (data.type == "pickup") {
      if (data.status === "Waiting" || data.status === "On van") {
        push(`/properties/${state.propertyId}/pickup`);
      }
    } else {
      push(`/properties/${state.propertyId}/my-experience/${data.id}`);
    }
  };
</script>

<div id="headerid">
  {#if !reload}
    <div
      use:styling={{ background: backgroundColorView }}
      class="container-fluid row"
    >
      <div
        class="col s12 row navifation-cont {managementColors.general
          .colorMode == 'white'
          ? 'navifation-cont-border--white'
          : managementColors.general.colorMode == 'dark'
            ? 'navifation-cont-border--dark'
            : ''}"
        use:styling={{ background: backgroundColorView }}
      >
        {#if dataHueader}
          <NavigationTop
            bind:languageConection={handleLanguage}
            bind:logoUrl={propertyData.logo}
            bind:propertyName={propertyData.name}
            bind:propertyId={propertyData.id}
            bind:listLanguagesEnabled={languagesEnabled}
            bind:features={faeturesList}
            bind:backgroundColorView
            bind:colorLetter={managementColors.general.colorLetter}
            bind:managementColors
            bind:showBtnBack={propEmpty}
            bind:iframeFromLanding
            bind:lengthNamesPropery
            bind:requestsPendingOutput
          />
        {/if}
      </div>

      <div
        use:styling={{ background: backgroundColorView }}
        class="col s12 landing__body row"
      >
        <div class="landing_base">
          <div class="col s12"><br /></div>
          <div class="col l6 offset-l3 m12 s12 hello_name">
            <div class="row welcome-div col s12 padding-left-8">
              {#if auth}
                <span
                  class="text_welcome {$locale}"
                  use:styling={{
                    color: managementColors.general.colorLetterDates,
                  }}
                >
                  {$_("Hello")}
                </span>
                &nbsp;
                <span
                  class="name_welcome"
                  use:styling={{
                    color: managementColors.general.colorLetterDates,
                  }}
                >
                  {auth.guestName}
                </span>
                &nbsp; &nbsp;
                {#if auth.roomNumber}
                  <LocationPointer
                    bind:roomNumber={auth.roomNumber}
                    bind:managementColors
                  />
                {/if}
              {:else}
                <span
                  class="text_welcome {$locale}"
                  use:styling={{
                    color: managementColors.general.colorLetterDates,
                  }}
                >
                  {$_("Hello")}
                </span>
              {/if}
            </div>
          </div>
          {#if requestsPending > 0}
            <div
              class="col s12 m12 xl12 carrucel"
              class:base={requestsPending > 0}
              use:styling={{
                background: managementColors.general.backgroundDivFotter,
              }}
            >
              <div class=" content">
                <div class="col l6 offset-l3 m12 s12 padding-left-8">
                  <div class="col s12 l12 m12 xl12 padding_0">
                    <span
                      class="all-request"
                      use:styling={{
                        color: managementColors.general.colorLetterNumId,
                      }}
                    >
                      {$_("My experiences")}
                    </span>

                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <span
                      class="pending-text"
                      use:styling={{
                        color: managementColors.color,
                      }}
                      on:click={goMyRequests}
                    >
                      {$_("SEE ALL")}
                    </span>
                  </div>
                </div>
                <div
                  class="col l9 offset-l3 m12 s12 cards-lits padding-left-8"
                  id="cards-lits"
                >
                  {#if mySroll}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <i
                      class="material-icons arrow-left hide-on-med-and-down"
                      on:click={scollMoveLeft}>keyboard_arrow_left</i
                    >
                  {/if}

                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-no-static-element-interactions -->
                  <i
                    class="material-icons arrow-right hide-on-med-and-down"
                    on:click={scollMove}>keyboard_arrow_right</i
                  >
                  <ul class="row listCards">
                    {#each requestsList as request, i}
                      {#if request.status == "Validate" || request.status == "Waiting"}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                        <li
                          class="etiqueta status-white"
                          use:styling={{
                            background:
                              managementColors.general.backgroundColorCards,
                          }}
                          on:click={() => goMyItem(request)}
                        >
                          <div class=" base-text col s12 m8 l8 xl8">
                            <span
                              class="text"
                              use:styling={{
                                color: managementColors.general.colorLetter,
                              }}
                            >
                              {#if request.serviceName}
                                {request.requestText} & {$_(
                                  request.serviceName
                                )}
                              {:else}
                                {request.requestText}
                              {/if}
                            </span>
                          </div>
                          <div class="col s12 m4 l4 xl4 padding_0">
                            {#if request.comments && request.comments.length > 0}
                              <span
                                class="material-icons chat-active-not-read"
                                use:styling={{
                                  color:
                                    managementColors.general.colorLetterDates,
                                }}>question_answer</span
                              >
                              {#if request.comments.find((message) => !message.read)}
                                <div class="card--message__badge" />
                              {/if}
                            {/if}
                            <p class="base-action action">
                              <i class="material-icons"> error </i>
                              {$_(request.status)}
                            </p>

                            {#if request.comments && request.comments.length > 0 && messageNotRead(request.comments)}
                              <div class="chat-active" />
                            {/if}
                          </div>
                        </li>
                      {:else if request.status == "Processed" || request.status == "On van"}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <li
                          class="etiqueta status-yellow"
                          use:styling={{
                            background:
                              managementColors.general.backgroundColorCards,
                          }}
                          on:click={() => goMyItem(request)}
                        >
                          <div class=" base-text col s12 m8 l8 xl8">
                            <span
                              class="text"
                              use:styling={{
                                color: managementColors.general.colorLetter,
                              }}
                            >
                              {request.requestText}
                            </span>
                            <span
                              class="slash"
                              use:styling={{
                                color: managementColors.color,
                              }}>/</span
                            >
                            <span
                              class="name"
                              use:styling={{
                                color: managementColors.general.colorLetter,
                              }}
                            >
                              {request.serviceName}
                            </span>
                          </div>
                          <div class="col s12 m4 l4 xl4 padding_0">
                            {#if request.comments && request.comments.length > 0}
                              <span
                                class="material-icons chat-active-not-read"
                                use:styling={{
                                  color:
                                    managementColors.general.colorLetterDates,
                                }}>question_answer</span
                              >
                              {#if request.comments.find((message) => !message.read)}
                                <div class="card--message__badge" />
                              {/if}
                            {/if}
                            <p class=" base-action action">
                              <i class="material-icons"> watch_later </i>
                              {$_(request.status)}
                            </p>
                            {#if request.comments && request.comments.length > 0 && messageNotRead(request.comments)}
                              <div class="chat-active" />
                            {/if}
                          </div>
                        </li>
                      {:else if request.status == "Accepted"}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <li
                          class="etiqueta status-yellow"
                          use:styling={{
                            background:
                              managementColors.general.backgroundColorCards,
                          }}
                          on:click={() => goMyItem(request)}
                        >
                          <div class=" base-text col s12 m8 l8 xl8">
                            <span
                              class="text"
                              use:styling={{
                                color: managementColors.general.colorLetter,
                              }}
                            >
                              {request.requestText}
                            </span>
                            <span
                              class="slash"
                              use:styling={{
                                color: managementColors.color,
                              }}>/</span
                            >
                            <span
                              class="name"
                              use:styling={{
                                color: managementColors.general.colorLetter,
                              }}
                            >
                              {request.serviceName}
                            </span>
                          </div>
                          <div class="col s12 m4 l4 xl4 padding_0">
                            {#if request.comments && request.comments.length > 0}
                              <span
                                class="material-icons chat-active-not-read"
                                use:styling={{
                                  color:
                                    managementColors.general.colorLetterDates,
                                }}>question_answer</span
                              >
                              {#if request.comments.find((message) => !message.read)}
                                <div class="card--message__badge" />
                              {/if}
                            {/if}
                            <p class=" base-action action">
                              <i class="material-icons"> watch_later </i>
                              {$_(request.status)}
                            </p>
                            {#if request.comments && request.comments.length > 0 && messageNotRead(request.comments)}
                              <div class="chat-active" />
                            {/if}
                          </div>
                        </li>
                      {/if}
                    {/each}
                  </ul>
                </div>
              </div>
            </div>
          {/if}

          <div class="col l6 offset-l3 m12 s12 langding">
            <div class="row col s12 nopadding nomargin">
              {#each faeturesList as feature, i}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div on:click={() => goModule(feature)}>
                  <CardFeature
                    bind:index={feature.numOrder}
                    bind:id={feature.codename}
                    bind:img={feature.imgUrl}
                    bind:showName={feature.showName}
                    bind:secondImg={feature.secondImgUrl}
                    bind:name={feature.caption}
                    bind:question={feature.question}
                    bind:rowCardRequest
                    bind:managementColors
                    bind:backgroundColorView
                    bind:colorLetter={managementColors.general.colorLetter}
                    bind:backgroundColorCards={managementColors.general
                      .backgroundColorCards}
                  />
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
  {:else if managementColors.general}
    <div
      class="container-fluid reloading row"
      use:styling={{ background: managementColors.general.backgroundColor }}
    >
      <Reloading bind:managementColors />
    </div>
  {/if}
</div>

<style>
  .padding-left-8 {
    padding-left: 6px !important;
  }
  .pending-text {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 8px !important;
    margin-left: 50px;
    text-decoration: underline;
    cursor: pointer;
  }
  .all-request {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 0px;
    padding-top: 8px !important;
  }
  .hello_name {
    padding: 0px;
    margin-top: 10px;
  }

  .padding_0 {
    padding: 0px !important;
    position: relative;
  }

  .carrucel {
    padding: 0px !important;
  }

  #headerid {
    min-height: 100%;
    height: 100%;
  }

  .container-fluid {
    height: 100%;
  }

  .langding {
    position: relative;
    padding: 16px 0px 0px 0px !important;
  }

  .landing__body {
    height: auto;
    padding: 0px !important;
  }
  .landing_base {
    margin-top: 60px !important;
  }

  .welcome-div {
    display: flex;
    align-items: center;
    padding: 0px 7px 14px 10px !important;
    margin: 0px;
  }

  .navifation-cont {
    position: fixed;
    z-index: 10;
    padding: 0px;
    margin: 0px !important;
  }

  .navifation-cont-border--white {
    border-bottom: 1px solid #e3e3e3;
  }

  .navifation-cont-border--dark {
    border-bottom: 1px solid #686c6d;
  }

  .card__mod {
    width: 50%;
    float: left;
  }

  .card__mod--horizontal {
    width: 100% !important;
    float: left;
  }

  .nopadding {
    padding: 0px !important;
  }

  .nomargin {
    margin: 0px;
  }

  .text_welcome {
    font-size: 1.125rem;
  }

  .name_welcome {
    color: #212121;
    font-size: 1.125rem;
    font-weight: 700;
  }

  .reloading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card--message__badge {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #ff4855;
    top: 13px;
    right: 5px;
  }

  @media only screen and (min-width: 601px) {
    .card--message__badge {
      right: 115px;
    }

    .card__mod {
      width: 33%;
    }

    .text_welcome {
      font-size: 1.25rem;
    }

    .name_welcome {
      font-size: 1.25rem;
    }
  }
  @media only screen and (max-width: 601px) {
    .chat-active {
      top: 18px !important;
      background: red;
      position: relative;
      left: 14px !important;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-left: 14px;
      float: right !important;
    }

    .chat-active-not-read {
      margin-top: 25px;
      font-size: 16px;
      margin-left: 0px !important;
      margin-right: 10px !important;
      color: #686c6d;
      float: right !important;
    }

    .base-action {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
      width: 105px;
      float: left !important;
      margin-top: 20px !important;
      padding: 3px;
      border-radius: 4px;
      text-align: right !important;
      padding-right: 5px;
      margin-left: 15px;
    }

    .base-action > i {
      margin-right: 4px;
    }

    .listCards > li {
      box-shadow: 0px 0px 8px #00000029;
      border-radius: 4px;
      min-height: 40px !important;
      height: auto !important;
      width: 85vw !important;
      margin: 0px 15px 0px 0px !important;
    }
  }

  .base {
    width: 100vw;
    min-height: 110px;
    background: #f4f4f4;
  }

  .listCards {
    display: inline-flex;
  }

  .listCards > li {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 4px;
    min-height: 50px !important;
    height: auto !important;
    width: 400px;
  }
  .base-text {
    position: relative;
    top: 15px;
    float: left !important;
    width: 250px;
    color: #212121;
    padding-left: 15px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0px !important;
  }

  .base-action {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    width: 105px;
    float: right;
    margin-top: 14px;
    padding: 3px;
    border-radius: 4px;
    text-align: right;
    padding-right: 5px;
  }

  .base-action > i {
    margin-right: 4px;
  }

  .status-yellow > div > .text {
    font-weight: 400;
  }

  .status-yellow > div > .name {
    font-weight: 700;
  }

  .status-yellow > div > .action {
    background: #ffeddb;
  }
  .status-yellow > div > .action > i {
    font-size: 16px;
    position: relative;
    top: 0px;
    color: #ffa64d;
    float: left;
  }
  .status-white > div > .text {
    font-weight: 700;
  }

  .status-white > div > .action {
    background: #f4f4f4;
  }
  .status-white > div > .action > i {
    font-size: 16px;
    position: relative;
    top: 0px;
    color: #686c6d;
    float: left;
  }

  .cards-lits {
    overflow-x: auto !important;
  }
  .chat-active {
    top: -23px;
    background: red;
    position: relative;
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .chat-active-not-read {
    margin-top: 18px;
    font-size: 16px;
  }

  .right-arrow {
    margin-top: 45px !important;
    margin-left: 10px !important;
    font-size: 40px;
    cursor: pointer;
  }

  .left-arrow {
    margin-top: 45px !important;
    margin-left: 10px !important;
    font-size: 40px;
    cursor: pointer;
  }
  .etiqueta {
    cursor: pointer;
    margin-right: 10px;
  }
  .arrow-left {
    position: absolute;
    margin-top: 20px;
    font-size: 30px;
    background: #212121;
    opacity: 0.6;
    z-index: 1001;
    border-radius: 50px;
    color: #f4f4f4;
    cursor: pointer;
    padding: 5px;
    transition: opacity 500ms;
  }
  .arrow-right {
    position: absolute;
    margin-top: 20px;
    font-size: 30px;
    background: #212121;
    opacity: 0.6;
    z-index: 1001;
    border-radius: 50px;
    color: #f4f4f4;
    cursor: pointer;
    padding: 5px;
    margin-right: 15px;
    right: 0px;
    transition: opacity 500ms;
  }

  .arrow-left.scrolled {
    opacity: 1;
  }
  .arrow-right.scrolled {
    opacity: 1;
  }
</style>
